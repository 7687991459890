import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import { Editor } from '@tinymce/tinymce-react';
import { Col, Row, DatePicker } from 'antd';
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct, IPrice, IProductObject, IMeasurementUnit, ICustomerGroup } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility/nhost";
import { GET_ALL_CUSTOMERS_WITH_ORGID, GET_ALL_CUSTOMER_GRPS_WITH_ORGID, GET_ALL_PRODUCTS_WITH_ORGID, GET_ALL_TAXES, GET_CATEGORIE, ORG_NAME, PRODUCT_UNIQUE_ID } from '../../../query';
import { GET_MEASURINGUNIT } from '../../../query';
import { jsonConverter } from '../../../components/helpers/jsonConverter';
import { VoidExpression } from "typescript";
import { Navigate, useParams } from "react-router-dom";
import { GET_ALL_CATEGORIES, GET_CATEGORIES_BY_ORGID } from "query";
import { ModalFooter } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any
};

const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};



interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  let childNode = children;

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  // name: string;
  // age: string;
  // address: string;
  pricelist: string,
  initialprice: string,
  fromquantity: string,
  percentage: string,
  dateform: string,
  dateto: string,
  finalprice: string,
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

const handleMenuClick: MenuProps['onClick'] = e => {
  message.info('Click on menu item.');
};

const handleMenuProductClick = () => {
  message.info('Please add product options before creating product variants. Product variants will be created based on the combination of the options you add.');
}

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

export interface templateOption {
  campaign_id: any;
  name: any;
  regular_price: any;
  monthly_cap: any;
  discount: any;
}
export const CreateProduct: React.FC<CreateProductProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
  tableQueryResult

}) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [productOption, setProductOption] = useState("");
  const [productOptionValue, setProductOptionValue] = useState("");
  const [productVariation, SetProductVariation] = useState([]);
  const [initialPrice, setInitialPrice] = useState(0)
  const [qualityPrice, setQualityPrice] = useState(0)
  const [percentagePrice, setPercentagePrice] = useState<any>([])
  const [totalfianlprice, setTotalfianlprice] = useState<any>([])
  const [percentageKey, setPercentageKey] = useState<any>()
  const [percentageCal, setPercentageCal] = useState<any>(false)
  const [onChangePercentage, setOnChangePercentage] = useState<any>(false)
  const [imageList, setImageList] = useState([] as any[]);
  const [urlList, setUrlList] = useState([] as any[]);
  const [thumbnailurlList, setThumbnailUrlList] = useState([] as any[]);
  const [productOptionData, setProductOptionData] = useState([] as any[]);
  const [checkedStatus, setCheckedStatus] = useState<any>(false);
  const [priceState, SetPriceState] = useState<any>();
  const [priceData, setPriceData] = useState<any>([]);
  const [measuringUnit, setMeasuringUnit] = useState("");
  const [priceList, setPriceList] = useState<any>([]);
  const [priceListData, setPriceListData] = useState<any>([]);
  const [customerPrivacy, setCustomerPrivacy] = useState(false);
  const [customerPrivacyGroup, setCustomerPrivacyGroup] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [prodCount, setProdCount] = useState<any>(null);
  const [orderUniqueNumber, setOrderUniqueNumber] = useState<any>(null);
  const [OrgName, setOrgName] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [allCategories, setAllCategories] = useState<any>([]);
  const [catecoryPopup, setCatecoryPopup] = useState<any>(false);
  const [products, setProducts] = useState<any>([]);
  const [customers, setCustomers] = useState<any>([]);
  const [customerGrps, setCustomerGrps] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write",);
  const [vatClassId, setVatClassId] = useState<any>(null);
  const [allTaxes, setAllTaxes] = useState<any>(null);

  const userId = user?.metadata?.org_id;
  const userData = user;
  const orgName = user?.orgname_prefix;  

  const onChangePriceValue = (data: any, e: any) => {
    let datas: any = [...priceData]
    let isExist = datas?.findIndex((d: any) => d?.value === data?.value);
    if (isExist !== -1) {
      datas[isExist].price = e.target.value;
    } else {
      let mockPrice = {
        label: data.label,
        value: data.value,
        price: e.target.value
      }
      datas.push(mockPrice);
    }
    setPriceData(datas);
    // setInitialPrice(datas)
  };

  const onChangeSelctedPrice = (data: any) => {
    setPriceList(data);
  };

  const onChangePriceVariantCheckbox = (e: CheckboxChangeEvent) => {
  };

  const { selectProps: OBProductMeasuringUnitSelectProps } = useSelect<IMeasurementUnit>({
    resource: "measurement_unit",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductAdminMeasuringUnitSelectProps } = useSelect<IMeasurementUnit>({
    resource: "measurement_unit",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });


  useEffect(() => {
    priceData?.forEach((data: any) => {
      if (data?.value == priceList) {

        setPriceListData((para: any) => [...para, data.price]);
      };
    });
  }, [priceList]);

  const onChangeCheckboxStatus = () => {
    setCheckedStatus(!checkedStatus);
  };

  useEffect(() => {
    GET_CATEGORIE(productOption).then((response: any) => {
      if (response == null) {
        setProductOptionData([])
      } else {
        setProductOptionData(response?.product_option)
      }
    });

  }, [productOption]);

  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name", "product_unique_id"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  PRODUCT_UNIQUE_ID({ ob_org_id: userId }).then((response: any) => {
    if(response?.products[0]?.product_unique_id){
      setProdCount(response?.products[0]?.product_unique_id)
      // function CountFunc(data: any) {
        let mock = response?.products[0]?.product_unique_id?.split('-');
        let count = Number(mock[1]) + 1;
        if (!isNaN(count)) {
          if(response?.products?.length > 0){
            let sencondMock = response?.products[0]?.product_unique_id?.split('-');
            let secondCount = Number(sencondMock[1]) + 1;
            let incrementvalue: any = (+secondCount);
            setOrderUniqueNumber(incrementvalue = ("0000" + incrementvalue).slice(-4))            
            return incrementvalue = ("0000" + incrementvalue).slice(-4);
          }
        }
        else{
          setOrderUniqueNumber("0001");
        }
      // };
    }
    else{
      setOrderUniqueNumber('0001');
      setProdCount(`${OrgName !== null || undefined ? OrgName : "TST"}/P - 0000`)
    }
  });

  useEffect(()=>{
    getAllTaxes()
  },[userId])

  const getAllTaxes = () => {
    GET_ALL_TAXES(userId).then((res: any) => {
      setAllTaxes(res?.taxes)
    })
  }

  const { selectProps: OBProductOptionSelectProps } = useSelect<IProductObject>({
    resource: "product_option",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "name",
        operator: "ne",
        value: "",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });



  const { selectProps: OBProductAdminSelectBuyProps } =
    useSelect<IProduct>({
      resource: "products",
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name",],
      },
      sort: [{ field: "name", order: "asc", },]
    });


  const { selectProps: OBBrandAdminSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductAdminOptionSelectProps } = useSelect<IProductObject>({
    resource: "product_option",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerAdminSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgAdminSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categoryAdminSelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListAdminSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: vatcalssSelectProps } = useSelect<IPrice>({
    resource: "vat_class",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value:any) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  let vatFilters:any = [
    {
      field: "ob_org_id",
      operator: "eq",
      value: userId,
    },
  ];
  
  if (vatClassId !== null) {
    vatFilters.push({
      field: "vat_class",
      operator: "eq",
      value: vatClassId,
    });
  }
  const { selectProps: taxSelectProps } = useSelect<IPrice>({
    resource: "taxes",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: vatFilters,
    onSearch: (value:any) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
    metaData: {
      fields: ["id", "name", "sage_tax_code"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { options: originalOptions, ...restSelectProps } = taxSelectProps;

  const customizedOptions: any = originalOptions?.map(option => {
    let sageCode: any = allTaxes?.filter((data: any) => option.value === data?.id )
    return {
      ...option,
      label: `${option?.label} - ${sageCode?.[0]?.vat_rate}`,  // Combine name and sage_tax_code
    };
  });
  

  // // Customize the options to include both 'name' and 'code'
  // const customizedOptions = options.map(option => ({
  //     ...option,
  //     label: `${option.name} (${option.code})`,  // Combine name and code
  // }));

  const [privacy, setPrivacy] = useState(false)
  const editorRef = useRef(null);

  const log = () => {
    if (editorRef.current) {
    }
  };

  const onFinish = (values: any) => {
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const onChangeNumber = (value: number) => {
  };

  
  const getCategories = async () => {
    GET_CATEGORIES_BY_ORGID(userId).then((response: any) => {
        setAllCategories(response?.categories);
    });
};

console.log(allCategories,"all categories");

useEffect(() => {
    getCategories(); 
    getAllProducts(); 
    getCustomers();
    getAllCustomerGrps();
}, [userId, formProps?.initialValues]);
useEffect(() => {
    getCategories();    
}, []);
  const [dataSource, setDataSource] = useState<DataType[]>([
    {
      key: '1',
      pricelist: 'Enter PriceList',
      initialprice: 'Enter Initial Price',
      fromquantity: 'Enter From Quantity',
      percentage: 'Enter Percentage',
      dateform: 'Enter From Date',
      dateto: 'Enter To Date',
      finalprice: 'Enter Final Price',
    }
  ]);

  const [count, setCount] = useState(2);
  const [open, setOpen] = useState(false)

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter(item => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Price List',
      dataIndex: 'pricelist',
      // width: '30%',
      editable: true,
    },
    {
      title: 'Initial Price',
      dataIndex: 'initialprice',
      editable: true,
    },
    {
      title: 'From quantity',
      dataIndex: 'fromquantity',
      editable: true,
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percentage',
      editable: true,
    },
    {
      title: 'Date from',
      dataIndex: 'dateform',
      editable: true,
    },
    {
      title: 'Date to',
      dataIndex: 'dateto',
      editable: true,
    },
    {
      title: 'Final price',
      dataIndex: 'finalprice',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      pricelist: 'Enter PriceList',
      initialprice: 'Enter Initial Price',
      fromquantity: 'Enter From Quantity',
      percentage: 'Enter Percentage',
      dateform: 'Enter From Date',
      dateto: 'Enter To Date',
      finalprice: 'Enter Final Price',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const getCustomers = () => {
    GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((data:any) => {
      if(data){        
        setCustomers(data?.customers)
      }
    })
  }

  const getAllProducts = () => {
    GET_ALL_PRODUCTS_WITH_ORGID(userId).then((res:any) => {
      setProducts(() => []);
      setProducts(res?.products);
    })
  }

  const getAllCustomerGrps = () => {
    GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
      setCustomerGrps(() => []);
      setCustomerGrps(res?.customer_groups);
    })
  }

  const handleProductSearch = (productsearch: string) => {

    if (productsearch?.length > 0) {
      const filterProduct = products?.filter((prod: any) => {
        return prod?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setProducts([...filterProduct])
      }
      else {
        getAllProducts();
      }
    } else {
      getAllProducts();
    }
  };

  const handleCustomerSearch = (search:any) => {
    if(search?.length > 0){
    const searchValue = customers?.filter((data:any,i:number) => {            
     return data?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    if(searchValue?.length !== 0){
      setCustomers([...searchValue])
    }
    else{
      getCustomers();
    }
  }
  else{
    getCustomers();
  }
  }

  const handleCustomerGrpSearch = (customerGrpsearch: string) => {

    if (customerGrpsearch?.length > 0) {
      const filterProduct = customerGrps?.filter((cusGrp: any) => {
        return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setCustomerGrps([...filterProduct])
      }
      else {
        getAllCustomerGrps();
      }
    } else {
      getAllCustomerGrps();
    }
  };

  const onChangeCheckboxCustomer = (e: any) => {
    setCustomerPrivacy(!customerPrivacy);
  }

  const onChangeCheckboxPrivacyGroup = (e: any) => {
    setCustomerPrivacyGroup(!customerPrivacyGroup);
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox);
    setCustomerPrivacy(false);
    setCustomerPrivacyGroup(false);
  };
  const [tempOptions, setTempOptions] = useState({
    name: "",
    regular_price: 0,
    monthly_cap: 0,
    discount: 0,
    slider_value: 0,
  });

  const [options, setOptions] = useState<templateOption[]>([]);

  const handleOk = () => {
    setOpen(false)
  }


    ORG_NAME(userId).then((response: any) => {

      setOrgName(response?.ob_orgs[0]?.orgname_prefix);
    });


  function CountFunc(prodCount: any) {    
    if(prodCount != undefined || null){
    let mock = prodCount?.split('-');
    let count = Number(mock[1]) + 1;
    let incrementvalue: any = (+count);
    return incrementvalue = ("0000" + incrementvalue).slice(-4);
    }
    else{
      return 0
    }
  };


  // var pad = (function (num) {
  //   return function () {
  //     var str = String(num++);
  //     while (str.length < 4) str = "0" + str;
  //     return str;
  //   }
  // })(1);

  useEffect(() => {
    setOrgName(orgName?.ob_orgs?.[0]?.orgname_prefix);    
  }, [orgName]);

  const apidata = (e: any) => {    
    if(e.price_details != undefined){
    e.price_details.forEach((d: any, key: any) => {
      let selectedInitailPrice = priceData.find((price: any) => price.value == d.price_id).price;
      d.initial_price = selectedInitailPrice;
      let totalValue = d.initial_price * (100 - percentagePrice) / 100;
      d.final_price = totalfianlprice[key];

    });
    }  
    // const product_unique_id = (prodCount !== null) ? OrgName !== null || undefined ? OrgName : "TST" + "/P-" + CountFunc(prodCount) : OrgName !== null || undefined ? OrgName : "TST" + "/P-" + "0001";
    const product_unique_id = OrgName !== null || undefined ? OrgName + "/P-" + orderUniqueNumber : "TST" + "/P-" + orderUniqueNumber;

    e.priceData = priceData
    e.product_option = e.product_option ?? null;
    e.price_details = e.price_details ?? null;
    e.customer_price = e.customer_price ?? null;
    e.related_products = e.related_products ?? null;
    e.access_customer_ids = e.access_customer_ids ?? null;
    e.specifications = e.specifications ?? null;
    e.tags = e.tags ?? null;
    e.access_customergroup_ids = e.access_customergroup_ids ?? null;
    e.product_status_rules = e.product_status_rules ?? null;
    e.packagequantity = e.packagequantity ?? 0;
    e.is_active = e.is_active ?? false;
    e.upccode = e.upccode ?? 0;
    e.barcode = e.barcode ?? 0;
    e.minimum_quantity = e.minimum_quantity ?? 1;
    e.maximum_quantity = e.maximum_quantity ?? 100;
    e.box_quantity = e.box_quantity ?? 0;
    e.msrp = e.msrp ?? 0.00;
    e.quantity = e.quantity ?? 0.00;
    e.promote_category = e.promote_category ?? false;
    e.featured_product = e.featured_product ?? false;
    e.description = e.description ?? "Empty";
    e.status = e.status ?? "Available";
    e.vat_class = e.vat_class ?? null;
    e.tax_id = e.tax_id ?? null;

    
    // let brandId = e.brand_id ?? null;
    // brand_id: "${brandId}", 
    // const PublicMUTATIONAPI = `mutation MyMutation {
    //   insert_products(objects: { name: "${e.name}",price_data: ${jsonConverter(priceData)},product_option: ${jsonConverter(e.product_option)}, image: "${e.image}", description: "${e.description}",category_id: "${e.category_id}", length: ${e.length}, width: ${e.width}, height: ${e.height}, msrp: ${e.msrp}, minimum_quantity: ${e.minimum_quantity}, maximum_quantity: ${e.maximum_quantity}, quantity: ${e.quantity}, box_quantity: ${e.box_quantity}, 
    //   status: "${e.status}", vat_class: "${e.vat_class}", is_active: ${e.is_active}, price_details : ${jsonConverter(e.price_details)}, customer_price: ${jsonConverter(e.customer_price)}, related_products: ${jsonConverter(e.related_products)}, upccode: "${e.upccode}", barcode: "${e.barcode}", referencecode: "${e.referencecode}", packagequantity: ${e.packagequantity},
    //   metadescription: "${e.metadescription}", searchkeywords: "${e.searchkeywords}", promote_category: ${e.promote_category}, featured_product: ${e.featured_product}, deals: "${e.deals}", product_status_rules: ${jsonConverter(e.product_status_rules)},ob_org_id: "${user?.metadata?.org_id}"
    // }){
    //     affected_rows
    //   }
    // }
    // `
    let category = categorySelectProps?.options?.find((data:any) => {
      if(data?.value == e.category_id){
        return data?.label
      }
    })    
    let accessPrivate = e.access_private ?? false;
    // let accessCustomerId = e.access_customer_ids;
    let accessPrivateGroup = e.access_private_group ?? "empty";
    const MUTATIONAPI = `mutation MyMutation {
      insert_products(objects: {product_unique_id:"${product_unique_id}",name: "${e.name}",code: "${e.code}",price_data: ${jsonConverter(priceData)},product_option: ${jsonConverter(e.product_option)},image: ${e.image ? `"${e.image}"` : null},temp_img: ${e.thumbImg ? `"${e.thumbImg}"` : null}, description: "${e.description}",category_id: "${e.category_id}",  msrp: ${e.msrp}, minimum_quantity: ${e.minimum_quantity}, maximum_quantity: ${e.maximum_quantity}, quantity: ${e.quantity}, box_quantity: ${e.box_quantity}, 
      status: "${e.status}",categories: "${category?.label}", vat_class: ${e.vat_class ? `"${e.vat_class}"` : null}, is_active: ${e.is_active}, price_details : ${jsonConverter(e.price_details)}, customer_price: ${jsonConverter(e.customer_price)}, related_products: ${jsonConverter(e.related_products)}, upccode: "${e.upccode}", barcode: "${e.barcode}", referencecode: "${e.referencecode}", packagequantity: ${e.packagequantity},
      metadescription: "${e.metadescription}", searchkeywords: "${e.searchkeywords}", promote_category: ${e.promote_category}, featured_product: ${e.featured_product}, deals: "${e.deals}", product_status_rules: ${jsonConverter(e.product_status_rules)}, access_private: ${accessPrivate},
      brand_id: ${e.brand_id ? `"${e.brand_id}"` : null},tax_id: ${e.tax_id ? `"${e.tax_id}"` : null},
      access_customer_ids: ${jsonConverter(e.access_customer_ids)}, access_private_group: "${accessPrivateGroup}", access_customergroup_ids: ${jsonConverter(e.access_customergroup_ids)},
       ob_org_id: "${user?.metadata?.org_id}",specifications : ${jsonConverter(e.specifications)},tags: ${jsonConverter(e.tags)}
    }){
        affected_rows
      }
    }
    `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI);
      if (data) {
        // handleFormSubmitted()
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 4000);
        tableQueryResult?.refetch()
        closeCreateModal()
        form?.resetFields();
        return data
      }
    }
    imageSubmit() 
    setUrlList([]) 
    setThumbnailUrlList([]);
  }

  useEffect(() => {
    let QUERY = `
  query {
    product_option(where: {id: {_eq: "${productOption}"}}){
      id
      name
      list_value
    }
  }
  `;
    nhost.graphql.request(QUERY).then((value: any) => {
      setProductOptionValue(value?.data?.product_option[0]?.name);
      SetProductVariation(value?.data?.product_option[0]?.list_value);
    });
  }, [productOption])

  const setImageFunc = (e: any) => {
    let data = [];
    for (const file of e.target.files) {
      data.push(file)
    }
    setImageList(data);
  };


  const { selectProps: privacyGroupListSelectProps } = useSelect<ICustomerGroup>({
    resource: "customer_groups",
    fetchSize: 1000,
    optionLabel: "group_name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "group_name"],
    },
    sort: [{ field: "group_name", order: "asc", },]
  });

  const { selectProps: privacyGroupAdminSelectProps } = useSelect<ICustomerGroup>({
    resource: "customer_groups",
    fetchSize: 1000,
    optionLabel: "group_name",
    optionValue: "id",
    metaData: {
      fields: ["id", "group_name"],
    },
    sort: [{ field: "group_name", order: "asc", },]
  });

  const imageStyles = { width: '10%', paddingTop: '10px', paddingBottom: '15px', paddingRight: '10px' };

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [ data.secure_url]);
          })
          .catch(err => console.log(err)
          );
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  const uploadThumbnailImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setThumbnailUrlList(urlList => [ data.secure_url]);
          })
          .catch(err => console.log(err)
          );
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const removeImage = () => {
    setUrlList([])
  }
  const removeThumImage = () => {
    setThumbnailUrlList([])
  }

  useEffect(() => {
    if (percentageCal) {
      let totalValue = priceListData[percentageKey] * (100 - percentagePrice[percentageKey]) / 100
      if (totalfianlprice[percentageKey]) {
        let totalprice = totalfianlprice.slice(0, percentageKey)
        setTotalfianlprice(totalprice)
      }
      setTotalfianlprice((para: any) => [...para, totalValue]);
    }
  }, [onChangePercentage]);

  const ontabclick = (key: any) => {
    setActiveTab(key);
  };
  const handleCategoryClose = () => {
    setCatecoryPopup(false);
    // setSearchValue("");
  }
  const handleCategory = () => {
    console.log(searchValue,"commig");
    const MUTATIONCATEGORYAPI = `mutation MyMutation {
      insert_categories(objects: {
        name: "${searchValue}",
        ob_org_id: "${userId}",
        is_active: ${true},
    }){
        affected_rows
        returning {
          id
        }
        
      }
    }
    `
    const callApi = async () => {
      const { data }:any = await nhost.graphql.request(MUTATIONCATEGORYAPI);
      if(data){
        let getId = data?.insert_categories?.returning[0]?.id
        form.setFieldsValue({...formProps?.initialValues, category_id: getId,});
        getCategories();
        // setSearchValue("");
        setCatecoryPopup(false);
      }
    }
    callApi();
  }

  const handleSearch = (value:string) => {
    if(value != "") {
    setSearchValue(value)
    let findCat = [...allCategories]?.filter((cat:any) => {
      return cat?.name?.toLowerCase().includes(value?.toLowerCase())
    })
    if(findCat?.length > 0){
      setAllCategories([...findCat])
    }
    if(findCat?.length == 0){
      setAllCategories([])
    }
  }
    else{
      getCategories();
    }
  };

  const addnewCategory = (e:any) => {
    setCatecoryPopup(true)
  }
  
  const renderNotFoundContent = () => {  
    if(searchValue.trim().length > 0){  
    return <div style={{display:"flex", justifyContent: "center"}}><Button style={{margin: "10px", backgroundColor: "#4EA144", color: "white", borderRadius: "10px"}} onClick={addnewCategory}>Add New Category</Button></div>
    }
  };

  useEffect(() => {
    setValue();
  },[formProps?.initialValues != undefined])
  const setValue = () => {
    form.setFieldsValue({...formProps?.initialValues});
  }

  const validateMinQuantity = async (_:any, minimum_quantity:any) => {    
    const max_quantity = form?.getFieldValue("maximum_quantity");
    if(minimum_quantity == 0) {
      throw new Error("Minimum Quantity cannot be Zero")
    }
  };

  const validateMaxQuantity = async (_:any, maximum_quantity:any) => {    
    const min_quantity = form?.getFieldValue("minimum_quantity");
    if(maximum_quantity == 0) {
      throw new Error("Maximum Quantity cannot be Zero")
    }
  };

  const vatclassChange = (e:any) => {
    setVatClassId(e)
  }

  return (
    <>
      <Modal
        {...modalProps}
        title="Create Product"
        onCancel={closeCreateModal}
        visible={createModalVisible}
        footer={false}
      >
        <Form
          form={form}
          // {...formProps}
          onFinish={(e) => {
            e.image = urlList.join(" ");
            e.thumbImg = thumbnailurlList?.join(" ");
            apidata(e);
          }}
          // {...formProps}
          name="basic"
          wrapperCol={{ span: 23 }}
          autoComplete="off"
          layout="vertical"
        >
          <Tabs
            defaultActiveKey="1"
            onChange={onChange}
            onTabClick={ontabclick}
          >
            <TabPane tab="Product" key="1">
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please insert Product Name!' }]}
                        style={{ marginBottom: "12px" }}
                      >
                        <Input style={{ height: "40px" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="code"
                        label="Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input your code!",
                          },
                        ]}
                        style={{ marginBottom: "12px" }}
                      >
                        <Input style={{ height: "40px" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="description"
                    label="Description"
                    // rules={[{ required: true, message: 'Please input your description!' }]}
                  >
                    <TextArea rows={6} style={{ width: "100%" }}  maxLength={300} />
                  </Form.Item>
                  <Form.Item label="Main Image">
                    <div style={{ marginTop: "" }}>
                      <Dragger
                        showUploadList={false}
                        {...props}
                        name="image"
                        customRequest={uploadImages}
                        accept="image/*"
                      >
                        <p className="ant-upload-drag-icon">
                          <PictureOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Drag image file here to upload(or Click)
                        </p>
                      </Dragger>
                    </div>
                  </Form.Item>
                  <Form.Item style={{ width: "100%" }}>
                    <h1 style={{ marginTop: "10px", fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {urlList.map((url) => {
                      return (
                        <div>
                          <img style={imageStyles} src={url} />
                          {url ? (
                            <span>
                              <DeleteOutlined onClick={removeImage} />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                      // <img  onClick={removeImage}  style={{ width: '10%', paddingTop: '10px', paddingBottom: '15px', paddingRight: '10px' }} src={url} />
                    })}
                  </Form.Item>
                  <Form.Item label="Thumbnail Image">
                    <div style={{ marginTop: "" }}>
                      <Dragger
                        showUploadList={false}
                        {...props}
                        name="image"
                        customRequest={uploadThumbnailImages}
                        accept="image/*"
                      >
                        <p className="ant-upload-drag-icon">
                          <PictureOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Drag image file here to upload(or Click)
                        </p>
                      </Dragger>
                    </div>
                  </Form.Item>
                  <Form.Item style={{ width: "100%" }}>
                    <h1 style={{ marginTop: "10px", fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {thumbnailurlList.map((url) => {
                      return (
                        <div>
                          <img style={imageStyles} src={url} />
                          {url ? (
                            <DeleteOutlined onClick={removeThumImage} />
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                      // <img onClick={removeThumImage} style={{ width: '10%', paddingTop: '10px', paddingBottom: '15px', paddingRight: '10px' }} src={url} />
                    })}
                  </Form.Item>
                  {user?.defaultRole === "admin" ? (
                    <Form.Item
                      label="Category"
                      name="category_id"
                      rules={[{ required: true }]}
                    >
                      <Select {...categoryAdminSelectProps} />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Category"
                      name="category_id"
                      rules={[{ required: true }]}
                    >
                      {/* <Select {...categorySelectProps} /> */}
                      <Select
                        showSearch
                        onSearch={handleSearch}
                        filterOption={false}
                        notFoundContent={renderNotFoundContent()}
                        value={searchValue}
                      >
                        {allCategories?.length > 0 ? (
                          <>
                            {[...allCategories]?.map((opt: any) => {
                              return (
                                <Option value={opt?.id} key={opt?.id}>
                                  {opt?.name}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                  {user?.defaultRole === "admin" ? (
                    <>
                      {priceListAdminSelectProps?.options?.map((item) => {
                        return (
                          <Form.Item label={item.label}>
                            <Input
                              type="number"
                              min="0"
                              // required
                              onChange={(e) => onChangePriceValue(item, e)}
                            />
                          </Form.Item>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {priceListSelectProps?.options?.map((item) => {
                        return (
                          <Form.Item label={item.label}>
                            <Input
                              type="number"
                              min="0"
                              onChange={(e) => onChangePriceValue(item, e)}
                            />
                          </Form.Item>
                        );
                      })}
                    </>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="msrp"
                    label="MSRP"
                    // rules={[{ required: true, message: 'Please input your msrp!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "100%", height: "40px" }}
                      min="0"
                    />
                  </Form.Item>
                  <Form.Item
                    name="minimum_quantity"
                    label="Minimum Quantity"
                    rules={[{validator: validateMinQuantity}]}
                  >
                    <InputNumber
                    type="number"
                      defaultValue="1"
                      onChange={onChange}
                      style={{ width: "100%", height: "40px" }}
                      min="0"
                    />
                  </Form.Item>
                  <Form.Item
                    name="maximum_quantity"
                    label="Maximum Quantity"
                    rules={[
                      { validator: validateMaxQuantity },
                    ]}
                  >
                    <InputNumber
                    type="number"
                      defaultValue="100"
                      onChange={onChange}
                      style={{ width: "100%", height: "40px" }}
                      min="0"
                    />
                  </Form.Item>
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    // rules={[{ required: true, message: 'Please input your quantity!' }]}
                  >
                    <InputNumber
                      defaultValue="1000"
                      onChange={onChange}
                      style={{ width: "100%", height: "40px" }}
                      min="0"
                    />
                  </Form.Item>
                  <Checkbox onChange={onChangeCheckbox}>
                    Track inventory
                  </Checkbox>
                  <Checkbox onChange={onChangeCheckbox}>
                    Allow backorder
                  </Checkbox>
                  <Form.Item
                    name="box_quantity"
                    label="Box Quantity / Multiples"
                    // rules={[{ required: true, message: 'Please input your boxquantity!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "100%", height: "40px" }}
                      min="0"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    // rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="Available" name="status">
                        Available
                      </Select.Option>
                      <Select.Option value="Limited Stock" name="status">
                        Limited Stock
                      </Select.Option>
                      <Select.Option value="Pre- order" name="status">
                        Pre- order
                      </Select.Option>
                      <Select.Option value="Not Available" name="status">
                        Not Available
                      </Select.Option>
                      <Select.Option value="Discountinued" name="status">
                        Discountinued
                      </Select.Option>
                      <Select.Option value="Sold Out" name="status">
                        Sold Out
                      </Select.Option>
                      <Select.Option value="Test Product Status" name="status">
                        Test Product Status
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="VAT class"
                    name="vat_class"
                    // rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                    onSelect={vatclassChange}
                     {...vatcalssSelectProps}/>
                  </Form.Item>
                  <Form.Item
                    label="Tax"
                    name="tax_id"
                    style={{ width: "100%" }}
                  >
                  <Select options={customizedOptions}
                    {...restSelectProps}
                  />
                  </Form.Item>
                  {user?.defaultRole === "admin" ? (
                    <Form.Item label="Brand" name="brand_id">
                      <Select
                        {...OBBrandAdminSelectProps}
                        style={{ width: "100%", height: "40px" }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label="Brand" name="brand_id">
                      <Select
                        {...OBBrandSelectProps}
                        style={{ width: "100%", height: "40px" }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item label="" name="is_active" valuePropName="checked">
                    <Checkbox onChange={onChangeCheckbox}>Is active?</Checkbox>
                  </Form.Item>
                  {/* <Form.Item name="tags" label="Tags">
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Add tags"
                      allowClear
                      tokenSeparators={[","]}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                        name={["specifications", "weight"]}
                        label="Weight"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "ingredients"]}
                        label="Ingredients"
                      >
                        <TextArea rows={3} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "storage"]}
                        label="Storage"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "cooking"]}
                        label="Cooking"
                      >
                        <TextArea rows={2} />
                      </Form.Item> */}
                  <Form.Item name="created_by" initialValue={user?.id}>
                    <Input type="hidden" />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Details" key="21">
              <Form.Item name="tags" label="Tags">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Add tags"
                  allowClear
                  tokenSeparators={[","]}
                ></Select>
              </Form.Item>
              <Form.Item name={["specifications", "weight"]} label="Weight">
                <Input type="number"  min="0"/>
              </Form.Item>
              <h6>Details</h6>
              <Form.List name={["specifications", "details"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                          style={{width: "250px"}}
                        >
                          <Select>
                            <Select.Option value="Detail Description">
                              Detail Description
                            </Select.Option>
                            <Select.Option value="Ingredients">
                              Ingredients
                            </Select.Option>
                            <Select.Option value="Storage">
                              Storage
                            </Select.Option>
                            <Select.Option value="Cooking">
                              Cooking
                            </Select.Option>
                            <Select.Option value="Allergens">
                              Allergens
                            </Select.Option>
                            <Select.Option value="May contain">
                              May contain
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          label="Value"
                          style={{width: "630px"}}
                        >
                          <ReactMde
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={(markdown: any) =>
                              Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>
                              )
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        style={{ width: "340px" }}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Details
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <h6>Nutritional Informations</h6>
              <Form.List name={["specifications", "nutritional_information"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                        >
                          <Input placeholder="Like Fat, protein, etc.." />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          label="Value"
                        >
                          <Input placeholder="Like 2g, 5g, etc.." />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        style={{ width: "340px" }}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Nutritional
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </TabPane>
            <TabPane tab="Discounts" key="2">
              <div>
                <Form.List name="price_details">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          {user?.defaultRole === "admin" ? (
                            <Form.Item
                              {...restField}
                              name={[name, "price_id"]}
                              label="Price list"
                              // rules={[{ required: true, }]}
                            >
                              <Select
                                {...priceListAdminSelectProps}
                                style={{ width: "150px" }}
                                onChange={onChangeSelctedPrice}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              {...restField}
                              name={[name, "price_id"]}
                              label="Price list"
                              // rules={[{ required: true, }]}
                            >
                              <Select
                                {...priceListSelectProps}
                                style={{ width: "150px" }}
                                onChange={onChangeSelctedPrice}
                              />
                            </Form.Item>
                          )}
                          <Form.Item
                            {...restField}
                            name={[name, "initial_price"]}
                            label="Initial Price"
                          >
                            <p style={{ display: "none" }}>{priceListData}</p>
                            <Input
                              placeholder="Initial Price"
                              value={priceListData[key]}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "from_quality"]}
                            label="From Quantity"
                            // rules={[{ required: true, message: 'Missing to from quality' }]}
                          >
                            <Input
                              placeholder="From Quantity"
                              onChange={(e: any) => {
                                setQualityPrice(e.target.value);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "percentage"]}
                            label="Percentage (%)"
                           
                            // rules={[{ required: true, message: 'Missing to percentage' }]}
                          >
                            <Input
                              placeholder="Percentage (%)"
                                type="number"
                                 min="0"
                              onChange={(e: any) => {
                                percentagePrice[key] = e.target.value;
                                setOnChangePercentage(!onChangePercentage);
                                setPercentageKey(key);
                                setPercentageCal(true);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "date_from"]}
                            label="Date From"
                            // rules={[{ required: true, message: 'Missing to date from' }]}
                          >
                            <DatePicker placeholder="Date From" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "date_to"]}
                            label="Date To"
                            // rules={[{ required: true, message: 'Missing to date to' }]}
                          >
                            <DatePicker placeholder="Date To" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "final_price"]}
                            label="Final Price"
                          >
                            <p style={{ display: "none" }}>{totalfianlprice}</p>
                            <Input
                              value={totalfianlprice[key]}
                              disabled
                              placeholder="Final Price"
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                          block
                          icon={false}
                          style={{ width: "16%" }}
                        >
                          <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Discounts
                        </div>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </TabPane>
            <TabPane tab="Customer Prices" key="3">
              <div>
                <Form.List name="customer_price">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            alignItems: "end",
                          }}
                          align="baseline"
                        >
                          {user?.defaultRole === "admin" ? (
                            <Form.Item
                              {...restField}
                              name={[name, "customer_id"]}
                              label="Customer"
                              style={{ width: "210px" }}
                            >
                              <Select {...OBCustomerAdminSelectProps} />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              {...restField}
                              name={[name, "customer_id"]}
                              label="Customer"
                              style={{ width: "210px" }}
                            >
                              <Select {...OBCustomerSelectProps} />
                            </Form.Item>
                          )}
                          <Form.Item
                            {...restField}
                            name={[name, "percentage"]}
                            label="Percentage (%)"
                          >
                            <Input placeholder="Percentage (%)"   type="number"  min="0" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                          block
                          icon={false}
                          style={{ width: "184px" }}
                        >
                          <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Customer Prices
                        </div>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </TabPane>

            <TabPane tab="Related Products" key="4">
              <div>
                <Form.Item label="Related Products" name="related_products">
                  <Select
                    mode="multiple"
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Products"
                    onSearch={handleProductSearch}
                    filterOption={false}
                  >
                    {products?.map((e: any, i: any) => {
                      return <Option key={e?.id}>{e?.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                {/* <Form.List name="related_products">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8, alignItems: "end" }} align="baseline">
                          {user?.defaultRole === "admin" ?
                            <Form.Item
                              {...restField}
                              name={[name, 'product_id']}
                              label="Product"
                              style={{ width: "210px" }}
                            >
                              <Select {...OBProductAdminSelectBuyProps} />
                            </Form.Item>
                            :
                            <Form.Item
                              {...restField}
                              name={[name, 'product_id']}
                              label="Product"
                              style={{ width: "210px" }}
                            >
                              <Select {...OBProductSelectBuyProps} />
                            </Form.Item>
                          }
                          <MinusCircleOutlined onClick={() => remove(name)} style={{ margin: "0px 0px 10px -105px" }} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "195px" }}>
                          Add Related Products
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> */}
              </div>
            </TabPane>

            <TabPane tab="Advanced" key="10">
              <div>
                <Form.Item
                  name="upccode"
                  label="UPC code"
                  // rules={[{ required: true, message: 'Please input your upc code!' }]}
                >
                  <Input style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item
                  name="barcode"
                  label="Barcode"
                  // rules={[{ required: true, message: 'Please input your barcode!' }]}
                >
                  <Input style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item
                  name="referencecode"
                  label="Reference code"
                  // rules={[{ required: true, message: 'Please input your referencecode!' }]}
                >
                  <Input style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item
                  name="packagequantity"
                  label="Package quantity"
                  // rules={[{ required: true, message: 'Please input your packagequantity!' }]}
                >
                  <InputNumber
                    defaultValue="0.0"
                    onChange={onChange}
                    style={{ width: "50%" }}
                    min="0"
                  />
                </Form.Item>
                <Form.Item
                  name="metadescription"
                  label="Meta description"
                  // rules={[{ required: true, message: 'Please input your meta description!' }]}
                >
                  <TextArea rows={4} style={{ width: "50%" }} maxLength={500} />
                </Form.Item>
              </div>
              <Form.Item
                name="searchkeywords"
                label="Search keywords"
                // rules={[{ required: true, message: 'Please input your searchkeywords!' }]}
              >
                <Input style={{ width: "50%" }} />
              </Form.Item>
            </TabPane>

            <TabPane tab="Promotions" key="7">
              <Form.Item
                label=""
                name="promote_category"
                valuePropName="checked"
              >
                <Checkbox>Promote in category page</Checkbox>
              </Form.Item>
              <Form.Item
                label=""
                name="featured_product"
                valuePropName="checked"
              >
                <Checkbox>Show in Featured Products (homepage)</Checkbox>
              </Form.Item>
              <Form.Item
                label="show in deals page"
                name="deals"
                // rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Select>
                  <Select.Option
                    value="Only if discounts are Available"
                    name="deals"
                  >
                    Only if discounts are Available
                  </Select.Option>
                  <Select.Option value="Always" name="deals">
                    Always
                  </Select.Option>
                  <Select.Option value="Never" name="deals">
                    Never
                  </Select.Option>
                </Select>
              </Form.Item>
            </TabPane>

            <TabPane tab="Product Status Rules" key="11">
              <Form.List name="product_status_rules">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "from_quantity"]}
                          label="From Quantity"
                          // rules={[{ required: true, message: 'Missing from quantity' }]}
                        >
                          <Input placeholder="from quantity" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "to_quantity"]}
                          label="To Quantity"
                          // rules={[{ required: true, message: 'Missing to quantity' }]}
                        >
                          <Input placeholder="to quantity" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={false}
                        style={{ width: "250px" }}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Product Status Rules
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </TabPane>

            <TabPane tab="Product Options" key="12">
              <Form.List name="product_option">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        {user?.defaultRole === "admin" ? (
                          <Form.Item
                            {...restField}
                            label="Variation"
                            style={{ width: "150px" }}
                            name={[name, "product_option_id"]}
                          >
                            <Select
                              {...OBProductAdminOptionSelectProps}
                              onChange={(e: any) => {
                                setProductOption(e);
                              }}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            label="Variation"
                            style={{ width: "150px" }}
                            name={[name, "product_option_id"]}
                          >
                            <Select
                              {...OBProductOptionSelectProps}
                              onChange={(e: any) => {
                                setProductOption(e);
                              }}
                            />
                          </Form.Item>
                        )}
                        {productVariation !== undefined ? (
                          <>
                            <Form.Item
                              {...restField}
                              name={[name, "product_option"]}
                              initialValue={productOptionValue}
                              // rules={[{ required: true, message: 'Missing to quantity' }]}
                            >
                              <Input hidden />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="Variation Values"
                              style={{ width: "150px" }}
                              name={[name, "product_option_value"]}
                            >
                              <Select>
                                {productVariation?.map((category: any) => (
                                  <Select.Option
                                    key={category?.name}
                                    value={category?.name}
                                  >
                                    {category?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                        {user?.defaultRole === "admin" ? (
                          <Form.Item
                            {...restField}
                            label="Measuring Unit"
                            style={{ width: "150px" }}
                            name={[name, "product_measuring_unit"]}
                          >
                            <Select
                              {...OBProductAdminMeasuringUnitSelectProps}
                              onChange={(e: any) => {
                                setMeasuringUnit(e);
                              }}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            label="Measuring Unit"
                            style={{ width: "150px" }}
                            name={[name, "product_measuring_unit"]}
                          >
                            <Select
                              {...OBProductMeasuringUnitSelectProps}
                              onChange={(e: any) => {
                                setMeasuringUnit(e);
                              }}
                            />
                          </Form.Item>
                        )}
                        <>
                          {measuringUnit ? (
                            <Form.Item
                              name={[name, "product_measuring_unit_value"]}
                              label="Measuring Unit Value"
                            >
                              <Input style={{ width: "150px" }} />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </>
                        <Form.Item
                          name={[name, "product_option_variation_price"]}
                          label="Variation Price"
                        >
                          <Input style={{ width: "150px" }} type="number" min="0" />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={false}
                        style={{ width: "195px" }}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Product Option
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </TabPane>

            <TabPane tab="Access" key="13">
              <div>
                <Form.Item
                  label=""
                  name="access_private"
                  valuePropName="checked"
                >
                  <Checkbox
                    name="access_private"
                    onChange={onChangeCheckboxPrivacy}
                  >
                    Private
                  </Checkbox>
                </Form.Item>
                {privacy === true ? (
                  <>
                    {user?.defaultRole === "admin" ? (
                      <Form.Item
                        name="access_customer_id"
                        label="Customer"
                        style={{ width: "210px" }}
                        // rules={[{ required: true, message: 'Missing Customer name' }]}
                      >
                        {/* <Select {...OBCustomerSelectProps} /> */}
                      </Form.Item>
                    ) : (
                      // <Form.Item
                      //   name='access_customer_id'
                      //   label="Customer"
                      //   style={{ width: "210px" }}
                      // // rules={[{ required: true, message: 'Missing Customer name' }]}
                      // >
                      //   <Select {...OBCustomerSelectProps} />
                      // </Form.Item>
                      <Form.Item
                        label="Select Customers"
                        name="access_customer_ids"
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          onSearch={handleCustomerSearch}
                          // onChange={handleSelectedChange}
                          filterOption={false}
                        >
                          {customers?.map((data: any) => {
                            return <Option key={data?.id}>{data?.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    {/* <Form.Item
                        name="access_private_group"
                        label="Privacy groups"
                        // rules={[{ required: true }]}
                        style={{ width: "50%", display: "none" }}
                      >
                        <Input />
                      </Form.Item> */}
                    <Form.Item
                      name="access_customergroup_ids"
                      label="Customer Groups"
                      // style={{display: "none"}}
                      rules={[]}
                    >
                      {/* <Select {...privacyGroupListSelectProps} /> */}
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder=""
                        onSearch={handleCustomerGrpSearch}
                        filterOption={false}
                        defaultValue={
                          formProps?.initialValues?.access_customergroup_ids
                        }
                      >
                        {customerGrps?.map((e: any, i: any) => {
                          return <Option key={e?.id}>{e?.group_name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
              </div>
            </TabPane>
          </Tabs>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ marginInlineEnd: "10px" }}
              onClick={closeCreateModal}
              type="default"
            >
              Cancel
            </Button>
            <Button
              style={{ marginInlineStart: "10px" }}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "70%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>
            Product has been created successfully
          </span>
        </div>
      </Modal>
      <Modal
        visible={catecoryPopup}
        onCancel={handleCategoryClose}
        footer={null}
      >
        <div>
          <span>
            Do you want to create this <strong>{searchValue}</strong> category
          </span>
        </div>
        <ModalFooter></ModalFooter>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              margin: "10px",
              backgroundColor: "#4EA144",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleCategory}
          >
            Yes
          </Button>
          <Button
            style={{
              margin: "10px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleCategoryClose}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

