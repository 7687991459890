import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  InputNumber, Checkbox, useSelect, Select
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { UploadProps, Upload, DatePicker, message } from 'antd';
import { useState } from 'react';
import { IOrg } from "interfaces";
import { nhost } from "utility/nhost";
import dayjs from "dayjs";
import { CheckCircleOutlined, PictureOutlined } from '@ant-design/icons';

type CreatePagesProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any
};

const { TextArea } = Input;
const { Dragger } = Upload;

const onChange = (key: string) => {
};


export const CreatePages: React.FC<CreatePagesProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
  tableQueryResult
}) => {
  const { data: user } = useGetIdentity();
  const [textBoxModal, setTextBoxModal] = useState(false);
  const [imageList, setImageList] = useState([] as any[]);
  const [urlList, setUrlList] = useState([] as any[]);
  const [closemodel, setCloseModel] = useState(false);
  const [open, setOpen] = useState(false)
  const [onError, setonError] = useState(false)
  const userId = user?.metadata?.org_id;

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const handleActiveCheck = () => {
    setTextBoxModal(!textBoxModal);
  };

  const handleCancel = () => {
    setOpen(false)
  }

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [...urlList, data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const { selectProps:customerSelectProps } = useSelect<any>({
    resource: "customers",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "name",

    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });
  const { selectProps:privacyGroupSelectProps } = useSelect<any>({
    resource: "customer_groups",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "group_name"],
    },
    optionLabel: "group_name",
    optionValue: "group_name",

    onSearch: (value) => [
      {
        field: "group_name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });


  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: 'file',
    multiple: true,
    onChange(info) {
      let data = [];
      for (const file of info.fileList) {
        data.push(file)
      }
      setImageList(data);
    },
    onDrop(e) {
    },
  };

  const apidata = (e: any) => {
    e.footer_menu = e.footer_menu === undefined ? false :  e.footer_menu
    e.header_menu = e.header_menu === undefined ? false :  e.header_menu
    e.header_submenu = e.header_submenu === undefined ? false :  e.header_submenu
    
    let viewOrder = e?.view_order ?? "0";
    const MUTATIONAPI = `mutation MyMutation {
    insert_pages(objects: { name: "${e.name}",view_order:"${viewOrder}" file: "${e.file}", header_menu: ${e.header_menu}, header_submenu: ${e.header_submenu},footer_menu: ${e.footer_menu}, ob_org_id: "${user?.metadata?.org_id}",
    content: "${e.content}",meta_description: "${e.meta_description}",active: ${e.active},login: ${e.login},private: ${e.private},customer: "${e.customer}",privacy_group: "${e.privacy_group}",
  }){
      affected_rows
    }
  }
  `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)

      if (data) {
        // handleFormSubmitted()
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 4000);
        tableQueryResult?.refetch()
        closeCreateModal()
        return data;
      }
    }
    imageSubmit();
    // closeModal();
  }

  const closeModal = () => {
    setCloseModel(!closemodel);
  }

  return (
    <>

      <Modal  {...modalProps} title="Create Pages"
        // visible={modalProps?.visible === true ? true : false}
        // onOk={() => closeModal()}
        onCancel={closeCreateModal}
        visible={createModalVisible}

      >
        <Form {...formProps} onFinish={(e) => {
          e.file = urlList.join(",")
          apidata(e)
        }} size="large" layout="vertical">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
                { max: 100, message: "Name cannot exceed 100 characters" },
             
            ]}
          >
            <Input max="100" maxLength={101}/>
          </Form.Item>
          <div style={{ display: 'flex', float: 'left' }}>
            <Form.Item label="" name="header_menu" valuePropName="checked" >
              <Checkbox value="0"> Show at header menu </Checkbox>
            </Form.Item>
            <Form.Item style={{ marginLeft: '30px' }} label="" name="header_submenu" valuePropName="checked">
              <Checkbox> Show at header sub menu </Checkbox>
            </Form.Item>
            <Form.Item style={{ marginLeft: '30px' }} label="" name="footer_menu" valuePropName="checked">
              <Checkbox> Show at footer menu </Checkbox>
            </Form.Item>
          </div>
          {user?.defaultRole === "admin" ?
            <Form.Item
              style={{ width: '100%' }}
              label="Orderbit Organisation"
              name="ob_org_id"
              rules={[{}]} >
              <Select {...OBOrgSelectProps} />
            </Form.Item> : ""}
          <Form.Item
            name="content"
            label="Content"
            rules={[{}]}
            style={{ width: '100%' }}
          >
            {/* <Editor
            apiKey='your-api-key'
            // onInit={(evt, editor) => editorRef.current = editor}
            initialValue="<p>This is the initial content of the editor.</p>"
            init={{
              height: 200,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertd  atetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          /> */}
            <TextArea />
          </Form.Item>
          <Form.Item
            name="meta_description"
            label="Meta description"
            rules={[{}]}
          >
            <TextArea rows={4} maxLength={500} />
          </Form.Item>
          <Form.Item
            name="view_order"
            label="View Order"
            rules={[{}]}
          >
             <Input type="number" min="0" style={{ width: '100%' }}/>
          </Form.Item>
          <div style={{ display: 'flex', float: 'left', width: '100%' }}>
            <Form.Item label="" name="active" valuePropName="checked">
              <Checkbox> Active </Checkbox>
            </Form.Item>
            <Form.Item style={{ marginLeft: '30px' }} label="" name="login" valuePropName="checked">
              <Checkbox> Login Required  </Checkbox>
            </Form.Item>
            <Form.Item style={{ marginLeft: '30px' }} label="" name="private" valuePropName="checked">
              <Checkbox onClick={() => handleActiveCheck()}> Private ( By making a banner private, it will be visible only to selected customers. ) </Checkbox>
            </Form.Item>
          </div>
          {textBoxModal === true ?
            <>
              <Form.Item
                label="Customers"
                name="customer"
                // rules={[{}]}
                style={{ width: '100%' }}
              >
                <Select {...customerSelectProps} mode="multiple"/>
              </Form.Item>
              <Form.Item
                label="Privacy groups"
                name="privacy_group"
                // rules={[{}]}
              >
                <Select {...privacyGroupSelectProps} mode="multiple"/>
              </Form.Item>
            </>
            : ""}
          {/* <Form.Item
          label="Date"
          name="date"
          style={{ width: '100%' }}
          rules={[{}]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker
            style={{ marginTop: '10px' }}

          />
        </Form.Item> */}
          <div style={{ marginTop: '50px' }}>
            <Dragger {...props} name="file" customRequest={uploadImages}
              style={{ width: '100%', marginTop: '30px' }}>
              <p className="ant-upload-drag-icon">
                <PictureOutlined />
              </p>
              <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
            </Dragger>
          </div>
          <Form.Item
            style={{ width: '100%' }}
          >
            <h1 style={{ marginTop: '10px', fontSize: "14px" }}>Uploaded image will be displayed here</h1>
            {urlList.map((url) => {
              return <img style={{ width: '10%', padding: '5px' }} src={url} />
            })}
          </Form.Item>
          <Form.Item name="created_by"
            initialValue={user?.id}
          >
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal >

      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Created pages</span>
        </div>
      </Modal>
    </>
  );
};
